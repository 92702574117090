.DeviceNameHowTo {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  width: 50em;
  left: -280%;

  @media (max-width: 576px) {
    width: 25em;
    left: -85%;
  }
}

.DeviceNameHowTo img {
  width: 100%;
}