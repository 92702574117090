.app-background--top {
  background-color: #f3f3f3;
  height: 300px;

}

.app-background--top.fixed-top {
  z-index: -1;
}

.app-background--bottom {
  background-color: var(--color-wm-orange);
  height: 100px;
}

.app-background--bottom.fixed-bottom {
  z-index: -1;
}
