:root {
  --color-error: red;
  --color-label: #626d7a;
  --color-title: #11adc4;
  --color-wm-orange: #f1831c;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form label {
  color: var(--color-label);
  font-weight: 500;
}

.container {
  z-index: 0;
}

.error {
  color: var(--color-error);
}

.page-title {
  margin: 40px 0;
}

.title {
  color: var(--color-title);
}

.btn-primary {
  --bs-btn-bg: var(--color-title);
  --bs-btn-border-color: var(--color-title);
  --bs-btn-hover-bg: #13a0b5;
  --bs-btn-hover-border-color: #13a0b5;
  --bs-btn-active-bg: #178fa1;
  --bs-btn-active-border-color: #178fa1;
  --bs-btn-disabled-bg: #178fa1;
  --bs-btn-disabled-border-color: #178fa1;
}

