.CreateCasePage-viewport {
  width: 100%;
  overflow: hidden;
}

.CreateCasePage-viewport-stage {
  width: 300%;
  display: flex;
  position: relative;
  left: -100%;
  transition: left .25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

.CreateCasePage-viewport-stage .inactive {
  height: 1rem;
  overflow: hidden;
}

.CreateCasePage-viewport form {
  margin-top: 15px;
}
