.SelectionTiles-Tile.card {
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 7.5rem;
  padding: 1.5rem;
  text-align: center;
  word-wrap: break-word;
}

.SelectionTiles .col:last-child {
  flex: 1;
}