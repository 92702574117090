.HelpIcon-tooltip.tooltip {
  --bs-tooltip-bg: #fff;
}

.HelpIcon-tooltip.tooltip.show {
  opacity: 1;
}

.HelpIcon-tooltip.tooltip .tooltip-inner {
  background-color: transparent;
}

.HelpIcon-trigger {
  color: var(--bs-tertiary-color);
  font-size: large;
  cursor: pointer;
  float: right;
  clear: both;
}